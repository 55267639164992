import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import PaypalExpressBtn from "react-paypal-express-checkout";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { paymentPaypalConfirmed } from "../../actions/miniCartActions";
import "./Payment.css";

// https://developer.paypal.com/demo/checkout/#/pattern/responsive
// https://www.npmjs.com/package/react-paypal-express-checkout

const client = {
  sandbox: process.env.REACT_APP_PAYPAL_CLIENT_SANDBOX_KEY,
  production: process.env.REACT_APP_PAYPAL_CLIENT_PRODUCTION_KEY,
};

export interface PaymentPaypalProps {
  encomenda: number,
  total: number,
  currency: string
}

export default function PaymentPaypal({ encomenda, total, currency }: PaymentPaypalProps) {
  const [cancelado, setCancelado] = useState<boolean>(false);
  const [sucesso, setSucesso] = useState<boolean>(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const onSuccess = (payment) => {
    dispatch(paymentPaypalConfirmed(encomenda));

    setSucesso(true);
    setTimeout(function () {
      history.push("/encomendas");
    }, 2000);
  }

  const onCancel = () => {
    // this.props
    //   .dispatch(paymentPaypalCanceled(encomenda))
    //   .then(data => {
    //     this.setState({ cancelado: true });
    //   });
  }

  const onError = (err) => {
    alert("Erro no pagamento!");
    console.log("Error!", err);
  }
  return (
    <Row className="payment">
      {!sucesso ? (
        !cancelado ? (
          <Col xs={12} sm={12} md={12} lg={12}>
            <p><FormattedMessage id="pagamento.alterarTipoPagamento.p1" /> </p>
            {total && currency &&
              <PaypalExpressBtn
                env={process.env.REACT_APP_PAYPAL_ENV}
                client={client}
                currency={currency}
                total={total}
                onError={onError}
                onSuccess={onSuccess}
                onCancel={onCancel}
                style={{ color: "gold", zIndex: "1" }}
              />
            }
          </Col>
        ) : (
          <Col xs={12} sm={12} md={12} lg={12}>
            <p>
              <h1><FormattedMessage id="pagamento.alterarTipoPagamento.paypal.cancelado" /></h1>
            </p>
          </Col>
        )
      ) : (
        <Col xs={12} sm={12} md={12} lg={12}>
          <p>
            <h3><FormattedMessage id="pagamento.alterarTipoPagamento.paypal.sucesso" /></h3>
          </p>
        </Col>
      )}
    </Row>
  );
}